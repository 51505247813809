export const bankNames: { [key: string]: string } = {
	SCB: 'ธนาคารไทยพาณิชย์',
	KBANK: 'ธนาคารกสิกรไทย',
	KTB: 'ธนาคารกรุงไทย',
	BBL: 'ธนาคารกรุงเทพ',
	BAY: 'ธนาคารกรุงศรีอยุธยา',
	KK: 'ธนาคารเกียรตินาคิน',
	CITI: 'ธนาคารซิตี้แบ้งค์',
	CIMB: 'ธนาคารซีไอเอ็มบี (ไทย)',
	TTB: 'ธนาคารทหารไทยธนชาต',
	TISCO: 'ธนาคารทิสโก้',
	BAAC: 'ธ.ก.ส.',
	UOBT: 'ธนาคารยูโอบี',
	LH: 'ธนาคารแลนด์ แอนด์ เฮ้าส',
	SC: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
	BOC: 'ธนาคารแห่งประเทศจีน (ไทย)',
	BOFA: 'ธนาคารแห่งอเมริกาฯ',
	GSB: 'ธนาคารออมสิน',
	GH: 'ธนาคารอาคารสงเคราะห์',
	IBANK: 'ธนาคารอิสลามแห่งประเทศไทย',
	ICBC: 'ธนาคารไอซีบีซี (ไทย)',
	HSBC: 'ธนาคารฮ่องกงและเซี่ยงไฮ้',
	TRUEWALLET: 'ทรูมันนี่วอเลต (TrueMoney Wallet)',
}

export const Banks = [
	{
		key: "BAAC",
		name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
		image: "/bank-logo/baac.svg",
	},
	{
		key: "BAY",
		name: "ธนาคารกรุงศรีอยุธยา",
		image: "/bank-logo/bay.svg",
	},
	{
		key: "BBL",
		name: "ธนาคารกรุงเทพ",
		image: "/bank-logo/bbl.svg",
	},
	{
		key: "BOC",
		name: "ธนาคารแห่งประเทศจีน (ไทย)",
		image: "/bank-logo/boc.svg",
	},
	{
		key: "CACIB",
		name: "Crédit Agricole Corporate and Investment Bank",
		image: "/bank-logo/cacib.svg",
	},
	{
		key: "CIMB",
		name: "ธนาคารซีไอเอ็มบี (ไทย)",
		image: "/bank-logo/cimb.svg",
	},
	{
		key: "CITI",
		name: "ธนาคารซิตี้แบงก์",
		image: "/bank-logo/citi.svg",
	},
	{
		key: "GH",
		name: "ธนาคารอาคารสงเคราะห์",
		image: "/bank-logo/gh.svg",
	},
	{
		key: "GSB",
		name: "ธนาคารออมสิน",
		image: "/bank-logo/gsb.svg",
	},
	{
		key: "HSBC",
		name: "ธนาคารฮ่องกงและเซี่ยงไฮ้",
		image: "/banks/hsbc.svg",
	},
	{
		key: "IBANK",
		name: "ธนาคารอิสลามแห่งประเทศไทย",
		image: "/banks/ibank.svg",
	},
	{
		key: "ICBC",
		name: "ธนาคารไอซีบีซี (ไทย)",
		image: "/bank-logo/icbc.svg",
	},
	{
		key: "KBANK",
		name: "ธนาคารกสิกรไทย",
		image: "/bank-logo/kbank.svg",
	},
	{
		key: "KK",
		name: "ธนาคารเกียรตินาคินภัทร",
		image: "/bank-logo/kk.svg",
	},
	{
		key: "KTB",
		name: "ธนาคารกรุงไทย",
		image: "/bank-logo/ktb.svg",
	},
	{
		key: "LH",
		name: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
		image: "/bank-logo/lh.svg",
	},
	{
		key: "SC",
		name: "ธนาคารสแตนดาร์ดชาร์เตอร์ด",
		image: "/bank-logo/sc.svg",
	},
	{
		key: "SCB",
		name: "ธนาคารไทยพาณิชย์",
		image: "/bank-logo/scb.svg",
	},
	{
		key: "TBANK",
		name: "ธนาคารธนชาต",
		image: "/bank-logo/tbank.svg",
	},
	{
		key: "TISCO",
		name: "ธนาคารทิสโก้",
		image: "/bank-logo/tisco.svg",
	},
	{
		key: "TMB",
		name: "ธนาคารทหารไทย",
		image: "/bank-logo/tmb.svg",
	},
	{
		key: "TTB",
		name: "ธนาคารทหารไทยธนชาต",
		image: "/bank-logo/ttb.svg",
	},
	{
		key: "UOBT",
		name: "ธนาคารยูโอบี",
		image: "/bank-logo/uobt.svg",
	},
	{
		key: "TRUEWALLET",
		name: "ทรูมันนี่วอลเล็ท",
		image: "/bank-logo/truewallet.svg",
	},
	{
		key: "PEER2PAY",
		name: "Peer2Pay",
		image: "/bank-logo/peer2pay.svg",
	},
]

export const bankColors: { [key: string]: string } = {
	KBANK: '#138f2d',
	BBL: '#1e4598',
	KTB: '#1ba5e1',
	BAY: '#fec43b',
	SCB: '#4e2e7f',
	KK: '#199cc5',
	CITI: '#1583c7',
	CIMB: '#7e2f36',
	TTB: '#f2f5ff',
	TISCO: '#12549f',
	BAAC: '#4b9b1d',
	UOBT: '#0b3979',
	LH: '#6d6e71',
	SC: '#0f6ea1',
	BOC: '#c50f1c',
	BOFA: '#e11e3c',
	GSB: '#eb198d',
	GH: '#f57d23',
	IBANK: '#184615',
	ICBC: '#c50f1c',
	HSBC: '#fd0d1b',
	PEER2PAY: '#242E65',
}

export const getBankName = (code: string = 'UNKNOWN') => {
	code = code.toUpperCase()
	if (code === 'TRUEWALLET') {
		return 'ทรูมันนี่วอลเล็ท'
	}
	if (code === 'PAYONEX') {
		return 'พร้อมเพย์ (QR Code)'
	}
	if (bankNames.hasOwnProperty(code)) {
		return bankNames[code]
	}
	return code
}

export const getBankColor = (code: string = 'UNKNOWN') => {
	code = code.toUpperCase()
	if (bankColors.hasOwnProperty(code)) {
		return bankColors[code]
	}
	return '#fff'
}


export const moneyPattern = [
	100,
	200,
	300,
	400,
	500,
	1000,
	2000,
	5000,
]